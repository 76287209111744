

@media (min-width: 1080px) {
  :root {
    --cache-version: 1;
  }
}


@media (min-width: 1080px) {
  .hide-on-desktop {
    display: none;
  }
}


@media (min-width: 1080px) {
  html, body, .font-body {
    font: var(--font-desktop-body1);
  }

  h1, .heading-1 {
    font: var(--font-desktop-h1);
  }

  h2, .heading-2 {
    font: var(--font-desktop-h2);
  }

  h3, .heading-3 {
    font: var(--font-desktop-h3);
  }

  h4, .heading-4 {
    font: var(--font-desktop-h4);
  }

  h5, .heading-5 {
    font: var(--font-desktop-h5);
  }

  p {
    font: var(--font-desktop-body1);
  }

  strong {
    font: var(--font-desktop-body1-bold);
  }

  table thead {
    font: var(--font-desktop-body2-bold);
  }

  table tbody {
    font: var(--font-desktop-body2);
  }
}


@media (min-width: 1080px) {
  .footer__themes-title {
    font: var(--font-desktop-h4);
  }

  .footer__themes-logo img {
    max-width: 104px;
  }

  .footer__themes--bottom .footer__theme-link {
    font: var(--font-desktop-h5);
  }

  .footer__categories--wrapper {
    gap: var(--spacing-32);
  }

  .footer__categories--wrapper .footer__category {
    flex-basis: 16.6667%;
  }

  .footer__categories--wrapper .footer__category-main {
    font: var(--font-desktop-h4);
  }

  .footer__categories--wrapper .footer__subcategory {
    font: var(--font-desktop-meta-regular);
  }
}


@media (min-width: 1080px) {
  .footer__contacts {
    order: 2;
  }

  .footer__contacts-links {
    font: var(--font-desktop-body2-bold);
    justify-content: center;
  }

  .footer__applications-wrapper {
    gap: var(--spacing-48);
    flex-direction: row;
    align-items: center;
  }

  .footer__applications {
    gap: var(--spacing-48);
  }

  .footer__logo {
    display: none;
  }
}


@media (min-width: 1080px) {
  .footer__copyright {
    font: var(--font-desktop-meta-regular);
  }
}


@media (min-width: 1080px) {
  .container--main > header {
    position: relative;
  }
}


@media (min-width: 1080px) {
  .container--main {
    grid-template-rows: auto auto auto;
    grid-template-columns: auto minmax(981px, 1080px) auto;
    display: grid;
  }

  .container--main > .is-pinned {
    animation: .3s forwards slide-down;
    display: block;
    position: fixed;
    left: 0;
    right: 0;
  }

  .container--main > :not(.is-pinned) + .header-static {
    padding-top: 0;
  }

  .container--main > main {
    gap: var(--spacing-32);
    padding-top: var(--spacing-32);
    grid-template-rows: auto;
    grid-template-columns: [lend] 1fr[ldivider] 1fr[rdivider] 1fr[rend];
    display: grid;
  }

  .container--main > .header-static {
    gap: var(--spacing-24);
    padding-top: var(--spacing-24);
    grid-template-rows: auto;
    grid-template-columns: [lend] 1fr[ldivider] 1fr[rdivider] 1fr[rend];
    display: grid;
  }

  .container--main > footer {
    box-shadow: -50vw 0 0 var(--color-surface-default-strong), -25vw 0 0 var(--color-surface-default-strong), 25vw 0 0 var(--color-surface-default-strong), 50vw 0 0 var(--color-surface-default-strong);
  }

  .container--one-column .main--first-content, .container--one-column .main--second-content {
    padding: var(--spacing-24) 176px 0 176px;
  }

  .container--one-column .main--first-content {
    padding-bottom: 0;
  }

  .container--one-column .main--second-content {
    padding-top: 0;
  }

  .container--one-column .grid-233 {
    grid-template-columns: [lend] 1fr 1fr[rend];
  }

  .grid-113, .grid-233 {
    gap: var(--spacing-32);
    grid-column: lend / rend;
    grid-template-columns: [lend] 1fr[ldivider] 1fr[rdivider] 1fr[rend];
  }
}


@media (min-width: 1080px) {
  .header {
    padding: var(--spacing-8) var(--spacing-24);
    width: 1080px;
  }

  .is-pinned .header {
    width: auto;
    max-width: 1920px;
  }

  header:not(.is-pinned) {
    box-shadow: none;
  }

  header:not(.is-pinned) .header {
    height: var(--spacing-header-height);
    gap: var(--spacing-header-v-gap) var(--spacing-24);
    border-radius: var(--spacing-header-top-left-radius) var(--spacing-header-top-right-radius) var(--spacing-header-bottom-right-radius) var(--spacing-header-bottom-left-radius);
    flex-wrap: wrap;
    box-shadow: 0 4px 20px #0000001a;
  }
}


@media (min-width: 1080px) {
  .header-search {
    display: block;
    position: relative;
  }
}


@media (min-width: 1080px) {
  header:not(.is-pinned) .header-socials {
    flex: 1 1 0;
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }
}


@media (min-width: 1080px) {
  .header-logo {
    margin-right: var(--spacing-32);
  }

  header:not(.is-pinned) .header-logo {
    height: var(--spacing-header-brand-height);
    flex: 1 1 0;
    margin-right: 0;
  }

  header:not(.is-pinned) .header-logo__image {
    height: var(--spacing-header-brand-height);
  }
}


@media (min-width: 1080px) {
  header:not(.is-pinned) .header-controls {
    flex: 1;
  }
}


@media (min-width: 1080px) {
  .is-pinned .header-desktop-navigation {
    order: initial;
  }

  .is-pinned .header-desktop-navigation:not(:has(.overflow-menu.active)) {
    overflow: hidden;
  }

  .is-pinned .header-menu {
    max-width: 100%;
  }

  header:not(.is-pinned) .header-desktop-navigation {
    order: 1;
    width: 100%;
  }

  header:not(.is-pinned) .header-menu__list {
    justify-content: center;
  }
}


@media (min-width: 1080px) {
  .header-menu__item {
    font: var(--font-desktop-menu-item);
    line-height: 26px;
  }
}


@media (min-width: 1080px) {
  .mobile-navigation {
    display: none;
  }
}


@media (min-width: 1080px) {
  .header-menu-hamburger {
    cursor: pointer;
  }
}


@media (min-width: 1080px) {
  .megamenu-wrapper {
    padding: var(--spacing-32);
    gap: var(--spacing-32);
    grid-template: [r1-start] "logo search search search close" max-content [r1-end]
                   [r2-start] "stickers stickers stickers stickers stickers" max-content [r2-end]
                   [r3-start] "categories categories categories print print" max-content [r3-end]
                   [r4-start] "categories categories categories print print" max-content [r4-end]
                   [r5-start] "categories categories categories contact contact" max-content [r5-end]
                   [r6-start] "divider divider divider divider divider" max-content [r6-end]
                   [r7-start] "social social applications applications applications" max-content [r7-end]
                   / 1fr 1fr 1fr 1fr;
    left: calc(50% - 540px);
  }
}


@media (min-width: 1080px) {
  .megamenu-close {
    justify-content: flex-end;
    gap: var(--spacing-8);
    cursor: pointer;
    color: var(--text-primary);
    font: var(--font-desktop-button-large);
    -webkit-user-select: none;
    user-select: none;
    align-items: center;
    display: flex;
  }

  .megamenu-close svg * {
    fill: var(--color-icon-icon-button-primary-solid-normal-text-default);
  }

  .megamenu-close:hover {
    text-decoration: underline;
  }

  .megamenu-close:hover svg * {
    fill: var(--color-icon-icon-button-primary-solid-normal-text-hover);
  }
}


@media (min-width: 1080px) {
  .megamenu-stickers__item, .megamenu-stickers__item > a, .megamenu-stickers__item > span {
    font: var(--font-desktop-meta-regular);
  }
}


@media (min-width: 1080px) {
  .megamenu-categories {
    gap: var(--spacing-32);
    row-gap: var(--spacing-24);
    flex-wrap: wrap;
    width: 675px;
  }

  .megamenu-categories__item {
    row-gap: var(--spacing-12);
    flex-basis: 144px;
  }

  .megamenu-categories__item-title {
    font: var(--font-desktop-h4);
  }

  .megamenu-categories .megamenu-subcategories {
    gap: var(--spacing-8);
    font: var(--font-desktop-body2);
  }
}


@media (min-width: 1080px) {
  .megamenu-print {
    gap: var(--spacing-12);
    min-width: 210px;
  }

  .megamenu-print__title {
    font: var(--font-desktop-h4);
  }

  .megamenu-print__subtitle {
    font: var(--font-desktop-body2);
  }

  .megamenu-print__covers {
    gap: var(--spacing-32);
    flex-direction: column;
  }
}


@media (min-width: 1080px) {
  .megamenu-logo {
    margin-right: var(--spacing-32);
    display: flex;
  }
}


@media (min-width: 1080px) {
  .megamenu-applications {
    justify-content: flex-end;
  }

  .megamenu-applications__title {
    font: var(--font-desktop-h5);
  }

  .megamenu-applications__subtitle {
    font: var(--font-desktop-body2);
  }
}


@media (min-width: 1080px) {
  .megamenu-contact__title {
    font: var(--font-desktop-h4);
  }

  .megamenu-contact__email {
    font: var(--font-desktop-body2);
  }
}


@media (min-width: 1080px) {
  header.is-pinned .header .megamenu-backdrop--header {
    z-index: 4;
    display: block;
  }
}


@media (min-width: 1080px) {
  .megamenu-newsletter__title {
    font: var(--font-desktop-h5);
  }

  .megamenu-newsletter__email {
    font: var(--font-desktop-body2);
  }
}


@media (min-width: 1080px) {
  .section--columns-233 {
    grid-column: 1 / 4;
  }
}


@media (min-width: 1080px) {
  .section--columns-232 {
    grid-column: 1 / 3;
  }
}


@media (min-width: 1080px) {
  .typography--h1, .typography--heading-3xl {
    font: var(--typography-desktop-heading-3xl-font-size) / var(--typography-desktop-heading-3xl-line-height) var(--heading-3xl-font-family);
  }

  .typography--heading-2xl {
    font: var(--typography-desktop-heading-2xl-font-size) / var(--typography-desktop-heading-2xl-line-height) var(--heading-2xl-font-family);
  }

  .typography--h2, .typography--heading-xl {
    font: var(--typography-desktop-heading-xl-font-size) / var(--typography-desktop-heading-xl-line-height) var(--heading-xl-font-family);
  }

  .typography--h3, .typography--heading-lg {
    font: var(--typography-desktop-heading-lg-font-size) / var(--typography-desktop-heading-lg-line-height) var(--heading-lg-font-family);
  }

  .typography--h4, .typography--heading-md {
    font: var(--typography-desktop-heading-md-font-size) / var(--typography-desktop-heading-md-line-height) var(--heading-md-font-family);
  }

  .typography--h5, .typography--heading-sm {
    font: var(--typography-desktop-heading-sm-font-size) / var(--typography-desktop-heading-sm-line-height) var(--heading-sm-font-family);
  }

  .typography--heading-xs {
    font: var(--typography-desktop-heading-xs-font-size) / var(--typography-desktop-heading-xs-line-height) var(--heading-xs-font-family);
  }

  .typography--heading-2xs {
    font: var(--typography-desktop-heading-2xs-font-size) / var(--typography-desktop-heading-2xs-line-height) var(--heading-2xs-font-family);
  }

  .typography--inter-title {
    font: var(--typography-desktop-inter-title-font-size) / var(--typography-desktop-inter-title-line-height) var(--inter-title-font-family);
  }

  .typography--section-title {
    font: var(--typography-desktop-section-title-font-size) / var(--typography-desktop-section-title-line-height) var(--section-title-font-family);
  }

  .typography--subtitle1, .typography--subtitle-lg {
    font: var(--typography-desktop-subtitle-lg-font-size) / var(--typography-desktop-subtitle-lg-line-height) var(--subtitle-lg-font-family);
  }

  .typography--subtitle1-bold, .typography--subtitle-lg-bold {
    font: 700 var(--typography-desktop-subtitle-lg-font-size) / var(--typography-desktop-subtitle-lg-line-height) var(--subtitle-lg-bold-font-family);
  }

  .typography--subtitle2, .typography--subtitle-md {
    font: var(--typography-desktop-subtitle-md-font-size) / var(--typography-desktop-subtitle-md-line-height) var(--subtitle-md-font-family);
  }

  .typography--subtitle2-bold, .typography--subtitle-md-bold {
    font: var(--typography-desktop-subtitle-md-bold-font-size) / var(--typography-desktop-subtitle-md-line-height) var(--subtitle-md-bold-font-family);
  }

  .typography--body1, .typography--body-lg {
    font: var(--typography-desktop-body-lg-font-size) / var(--typography-desktop-body-lg-line-height) var(--body-lg-font-family);
  }

  .typography--body1-bold, .typography--body-lg-bold {
    font: 700 var(--typography-desktop-body-lg-font-size) / var(--typography-desktop-body-lg-line-height) var(--body-lg-bold-font-family);
  }

  .typography--body2, .typography--body-md {
    font: var(--typography-desktop-body-md-font-size) / var(--typography-desktop-body-md-line-height) var(--body-md-font-family);
  }

  .typography--body2-bold, .typography--body-md-bold {
    font: 700 var(--typography-desktop-body-md-font-size) / var(--typography-desktop-body-md-line-height) var(--body-md-bold-font-family);
  }

  .typography--meta-regular, .typography--body-sm {
    font: var(--typography-desktop-body-sm-font-size) / var(--typography-desktop-body-sm-line-height) var(--body-sm-font-family);
  }

  .typography--meta-bold, .typography--body-sm-bold {
    font: 700 var(--typography-desktop-body-sm-font-size) / var(--typography-desktop-body-sm-line-height) var(--body-sm-bold-font-family);
  }

  .typography--body-xs {
    font: var(--typography-desktop-body-xs-font-size) / var(--typography-desktop-body-xs-line-height) var(--body-xs-font-family);
  }

  .typography--body-xs-bold {
    font: 700 var(--typography-desktop-body-xs-font-size) / var(--typography-desktop-body-xs-line-height) var(--body-xs-bold-font-family);
  }

  .typography--button-large, .typography--button-lg {
    font: var(--typography-desktop-button-lg-font-size) / var(--typography-desktop-button-lg-line-height) var(--button-lg-font-family);
  }

  .typography--button-medium, .typography--button-md {
    font: var(--typography-desktop-button-md-font-size) / var(--typography-desktop-button-md-line-height) var(--button-md-font-family);
  }

  .typography--button-small, .typography--button-sm {
    font: var(--typography-desktop-button-sm-font-size) / var(--typography-desktop-button-sm-line-height) var(--button-sm-font-family);
  }

  .typography--menu-item {
    font: var(--typography-desktop-menu-item-font-size) / var(--typography-desktop-menu-item-line-height) var(--menu-item-font-family);
  }

  .typography--label {
    font: var(--typography-desktop-label-font-size) / var(--typography-desktop-label-line-height) var(--label-font-family);
  }

  .typography--helper-text {
    font: var(--typography-desktop-helper-text-font-size) / var(--typography-desktop-helper-text-line-height) var(--helper-text-font-family);
  }
}


@media (min-width: 1080px) {
  .author-card--author-detail .author-card__role {
    grid-area: 2 / 2;
  }
}


@media (min-width: 1080px) {
  .author-detail-wrapper {
    grid-column: lend / rdivider;
  }

  .author-card--author-detail {
    padding-bottom: var(--spacing-24);
  }

  .author-card--author-detail .social-media {
    grid-area: 1 / 3;
    justify-self: end;
  }

  .author-card--author-detail .author-card__mail-link {
    justify-self: end;
  }
}


@media (min-width: 1080px) {
  .breadcrumb {
    grid-column: 1 / 3;
  }
}


@media (min-width: 1080px) {
  .load-more-disabled__title {
    font: var(--font-desktop-h4);
  }

  .load-more-disabled__subtitle {
    font: var(--font-desktop-body2);
  }
}


@media (min-width: 1080px) {
  .promobox--horizontal {
    padding: 0;
  }
}


@media (min-width: 1080px) {
  .print__cover {
    gap: var(--spacing-12);
  }

  .print__cover > a > img {
    width: 128px;
    height: auto;
  }

  .print__cover .button {
    width: 128px;
  }
}


@media (min-width: 1080px) {
  .promo-subscription {
    align-self: flex-start;
  }
}


@media (min-width: 1080px) {
  .premium-card {
    height: 499px;
  }
}


@media (min-width: 1080px) {
  .topic__custom-article html, .topic__custom-article body, .topic__custom-article .font-body {
    font: var(--font-desktop-body1);
  }

  .topic__custom-article h1, .topic__custom-article .heading-1 {
    font: var(--font-desktop-h1);
  }

  .topic__custom-article h2, .topic__custom-article .heading-2 {
    font: var(--font-desktop-h2);
  }

  .topic__custom-article h3, .topic__custom-article .heading-3 {
    font: var(--font-desktop-h3);
  }

  .topic__custom-article h4, .topic__custom-article .heading-4 {
    font: var(--font-desktop-h4);
  }

  .topic__custom-article h5, .topic__custom-article .heading-5 {
    font: var(--font-desktop-h5);
  }

  .topic__custom-article p {
    font: var(--font-desktop-body1);
  }

  .topic__custom-article strong {
    font: var(--font-desktop-body1-bold);
  }

  .topic__custom-article table thead {
    font: var(--font-desktop-body2-bold);
  }

  .topic__custom-article table tbody {
    font: var(--font-desktop-body2);
  }
}


@media (min-width: 1080px) {
  .tabs-and-links .swiper__list {
    gap: var(--spacing-16);
  }
}


@media (min-width: 1080px) {
  .tabs-and-links__tabs {
    gap: var(--spacing-20);
  }
}


@media (min-width: 1080px) {
  .tabs-and-links__links {
    gap: var(--spacing-16) var(--spacing-48);
  }
}


@media (min-width: 1080px) {
  .tabs-and-links__link-title {
    font: var(--font-desktop-body1-bold);
  }
}


@media (min-width: 1080px) {
  .ad-position--desktop {
    display: block;
  }
}


@media (min-width: 1080px) {
  .ad-list-322, .ad-list-353 {
    grid-column: 1 / 3;
  }
}


@media (min-width: 1080px) {
  .ad-list-325 {
    grid-column: 1 / 3;
  }
}


@media (min-width: 1080px) {
  .wrapper--main > .grid-233 > aside {
    grid-column: 3;
    grid-row-start: 1;
    height: 100%;
    display: none;
  }

  .wrapper--main > .grid-233 > aside:has(.idvert-banner) {
    display: block;
  }

  .wrapper--main > .grid-233 > aside.grid-row-4 {
    grid-row-start: 4;
  }

  .wrapper--main > .grid-233 > aside .sidebar-content {
    height: 100%;
    max-height: 1800px;
  }

  .wrapper--main > .grid-233 > aside .sidebar-content > * {
    position: sticky;
    top: 0;
  }

  .wrapper--main > .grid-233 > aside .sidebar-content--top > *, .wrapper--main > .grid-233 > aside .sidebar-content--bottom > * {
    top: var(--ad-top-82);
  }

  .wrapper--main > .grid-233 > aside .sidebar-content:last-child {
    max-height: 100%;
  }
}


@media (min-width: 1080px) {
  .miniplayer {
    aspect-ratio: 16 / 9;
    margin: var(--spacing-24) 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    display: flex;
  }

  .miniplayer .vp-player {
    width: 330px;
  }
}


@media (min-width: 1080px) {
  .wiki-search {
    grid-column: 1 / 3;
  }

  .wiki-search__title {
    font: var(--font-desktop-h2);
  }
}


@media (min-width: 1080px) {
  .category-card-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}


@media (min-width: 1080px) {
  .category-info__description html, .category-info__description body, .category-info__description .font-body {
    font: var(--font-desktop-body1);
  }

  .category-info__description h1, .category-info__description .heading-1 {
    font: var(--font-desktop-h1);
  }

  .category-info__description h2, .category-info__description .heading-2 {
    font: var(--font-desktop-h2);
  }

  .category-info__description h3, .category-info__description .heading-3 {
    font: var(--font-desktop-h3);
  }

  .category-info__description h4, .category-info__description .heading-4 {
    font: var(--font-desktop-h4);
  }

  .category-info__description h5, .category-info__description .heading-5 {
    font: var(--font-desktop-h5);
  }

  .category-info__description p {
    font: var(--font-desktop-body1);
  }

  .category-info__description strong {
    font: var(--font-desktop-body1-bold);
  }

  .category-info__description table thead {
    font: var(--font-desktop-body2-bold);
  }

  .category-info__description table tbody {
    font: var(--font-desktop-body2);
  }
}


@media (min-width: 1080px) {
  .category-info {
    grid-column: lend / rdivider;
  }

  .category-info h1 {
    font: var(--font-desktop-h2);
  }

  .category-info__menu > ul {
    gap: var(--spacing-32);
    font: var(--font-desktop-body2);
    margin: var(--spacing-32) 0 var(--spacing-24);
    border-bottom: 1px solid var(--other-divider);
    display: flex;
  }

  .category-info__menu > ul ul {
    opacity: 0;
    padding: 0 var(--spacing-8) var(--spacing-8);
    background-color: var(--background-default);
    text-align: right;
    pointer-events: none;
    border-bottom: 0;
    flex-direction: column;
    gap: 0;
    position: absolute;
    top: 100%;
    right: 0;
    box-shadow: 0 2px 5px -3px #000;
  }

  .category-info__menu-item {
    white-space: nowrap;
    position: relative;
  }

  .category-info__menu-item--active {
    color: var(--text-primary);
    font: var(--font-desktop-body2-bold);
    border-bottom: 2px solid;
  }

  .category-info__menu-item .category-info__menu-item {
    opacity: 0;
    transition: all .2s ease-in-out;
    position: relative;
  }

  .category-info__menu-item .category-info__menu-item:nth-child(2n) {
    left: -50%;
  }

  .category-info__menu-item .category-info__menu-item:nth-child(odd) {
    left: 50%;
  }

  .category-info__menu-item:hover .category-info__menu-item {
    padding: var(--spacing-8);
    opacity: 1;
    border-bottom: 0;
    margin-left: auto;
    transition: all .2s ease-in-out;
    left: 0;
  }

  .category-info__menu-item.with-subcat {
    margin-left: auto;
  }

  .category-info__menu-item.with-subcat:hover {
    cursor: default;
  }

  .category-info__menu-item.with-subcat:hover ul {
    opacity: 1;
    pointer-events: initial;
    transition: opacity .2s ease-in-out;
  }

  .category-info__menu-item.with-subcat:hover .category-info__menu-item {
    width: 100%;
  }

  .category-info__menu-link {
    padding-bottom: var(--spacing-16);
    color: var(--text-secondary);
    text-decoration: none;
    display: block;
  }

  .category-info__menu-link:hover {
    color: var(--primary-main);
  }

  .category-info__menu-link:focus {
    background: var(--primary-outlined-hover-background);
  }

  .category-info__menu-item--active .category-info__menu-link {
    color: var(--text-primary);
  }

  .category-info__menu-item.with-subcat:hover .category-info__menu-link {
    padding-bottom: 0;
  }

  .category-info__menu-item.with-subcat:hover > .category-info__menu-link {
    color: var(--primary-main);
  }

  .category-info__subcat-vertical-list {
    z-index: 10;
  }

  .category-info__menu-arrow {
    transition: transform .2s ease-in-out;
    display: inline-block;
    transform: rotate(-90deg);
  }

  .category-info__menu-arrow path {
    fill: var(--text-secondary);
  }

  .category-info__menu-item:hover .category-info__menu-arrow {
    transition: transform .2s ease-in-out;
    transform: rotate(0);
  }

  .category-info__menu-item:hover .category-info__menu-arrow path {
    fill: var(--primary-main);
  }
}


@media (min-width: 1080px) {
  .seo-box {
    grid-column: 1 / 3;
  }
}


@media (min-width: 1080px) {
  .profile-user {
    min-width: 680px;
  }

  .profile-user strong {
    font: var(--font-desktop-body1-bold);
  }

  .profile-user__cancel-reccuring strong .recurring-info-popup, .profile-user__cancel-newsletter strong .recurring-info-popup {
    font: var(--font-desktop-meta-regular);
  }

  .profile-user__voucher-title {
    font: var(--font-desktop-body1-bold);
  }

  .form-review__field label, .form-review__field textarea {
    font: var(--font-desktop-meta-regular);
  }
}


@media (min-width: 1080px) {
  .list-articles-wrapper--vertical, .list-articles-wrapper--horizontal {
    grid-column: 1 / 3;
  }
}


@media (min-width: 1080px) {
  .topic-banner {
    flex-wrap: nowrap;
    justify-content: flex-start;
    width: 1080px;
  }
}


@media (min-width: 1080px) {
  .topic-banner__label {
    font: var(--font-desktop-meta-bold);
  }
}


@media (min-width: 1080px) {
  .topic-banner__title {
    font: var(--font-desktop-h3);
  }
}


@media (min-width: 1080px) {
  .megamenu-wrapper {
    padding: var(--spacing-32);
    gap: var(--spacing-32);
    grid-template: [r1-start] "logo search search search close" max-content [r1-end]
                   [r2-start] "stickers stickers stickers stickers stickers" max-content [r2-end]
                   [r3-start] "categories categories categories categories categories" max-content [r3-end]
                   [r4-start] "categories categories categories categories categories" max-content [r4-end]
                   [r5-start] "categories categories categories categories categories" max-content [r5-end]
                   [r6-start] "divider divider divider divider divider" max-content [r6-end]
                   [r7-start] "social contact contact contact contact" max-content [r7-end]
                   / 1fr 1fr 1fr 1fr;
  }
}


@media (min-width: 1080px) {
  .megamenu-categories {
    width: 100%;
  }

  .megamenu-categories__item {
    flex-basis: 177px;
  }
}